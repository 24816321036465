import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
	Box,
	Flex,
	Stack,
	Link,
	TableContainer,
	Table,
	Thead,
	Tbody,
	Th,
	Center,
	Spinner,
	Text,
	Td,
	InputGroup,
	InputLeftElement,
	Input,
	InputProps,
	Select,
	Button,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import * as Pdf from '@react-pdf/renderer'
import { Search2Icon } from '@chakra-ui/icons'

import { TablePagination } from 'app/components/TablePagination'
import { usePaymentsQuery } from 'modules/financial/queries/usePaymentsQuery'
import { useBillsQuery } from '../queries/useBillsQuery'
import { PAYMENT_METHODS } from 'modules/financial'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ProfessionalsRepository } from 'data/ProfessionalsRepository'
import api from 'services/api'
import { ReportPdf, ReportPdfDownload } from './ReportPdf'
import { Payment } from 'data/Payment'
import { Bill } from 'data/Bill'
import { GetPaymentsProps, PaymentsRepository } from 'data/PaymentsRepository'

const REPORTS = [
	{
		id: 'fiscal',
		children: <ReportFiscalEntrada />,
		title: 'Relatório fiscal de entrada',
	},
	{
		id: 'saida',
		children: <ReportFiscalSaida />,
		title: 'Relatório fiscal de saída',
	},
	{
		id: 'contabil_entrada',
		children: <ReportContabilEntrada />,
		title: 'Relatório contábil de entrada',
	},
	{
		id: 'contabil_saida',
		children: <ReportContabilSaida />,
		title: 'Relatório contábil de saída',
	},
	{
		id: 'estoque_entrada_saida',
		children: <ReportStockMovements />,
		title: 'Relatório de entradas/saídas de estoque',
	},
]

export function ReportsRoute() {
	const location = useLocation()
	const hash = location.hash

	return (
		<Stack gap="4" h="100%">
			<Flex as="nav" flexWrap="wrap" columnGap="4" rowGap="3">
				{REPORTS.map((link) => (
					<Link
						key={link.id}
						href={`#${link.id}`}
						fontWeight="500"
						color={
							hash === `#${link.id}` ? 'primary.700' : undefined
						}
					>
						{link.title}
					</Link>
				))}
			</Flex>

			{REPORTS.map((report) => (
				<Box
					key={report.id}
					id={report.id}
					display={hash === `#${report.id}` ? 'block' : 'none'}
					h="100%"
				>
					{report.children}
				</Box>
			))}
		</Stack>
	)
}

function ReportFiscalEntrada() {
	const [localSearchParams, setLocalSearchParams] = useState(
		new URLSearchParams(window.location.search)
	)
	const page = Math.abs(Number(localSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setLocalSearchParams((init) => {
			init.set('page', String(nextPage))
			return new URLSearchParams(init)
		})
	}

	const searchObject = Object.fromEntries(localSearchParams)
	const hasSearch = Boolean(
		localSearchParams.get('search') ||
			localSearchParams.get('startDate') ||
			localSearchParams.get('endDate') ||
			localSearchParams.get('dentist')
	)

	const queryParams = {
		...searchObject,
		page,
		status: 'paid',
		withNote: true,
	}
	const query = usePaymentsQuery(queryParams, { enabled: hasSearch })
	const payments = query.data?.data
	const paginationInfo = query.data?.meta
	const isEmpty = payments?.length <= 0
	const total = query.data?.totalBy?.all

	const dentistsQuery = useQuery({
		queryKey: ['professionals'],
		queryFn: new ProfessionalsRepository().getProfessionals,
	})
	const dentists = dentistsQuery.data

	type Column = { title: string; render: (it: Payment) => string }
	const columns: Column[] = [
		{ title: 'Paciente', render: (it) => it.customer?.name },
		{
			title: 'CPF Paciente',
			render: (it) =>
				it.customer?.cpf?.replace(
					/(\d{3})(\d{3})(\d{3})(\d{2})/,
					'$1.$2.$3-$4'
				) ?? '-',
		},
		{
			title: 'CPF Pagador',
			render: (it) =>
				it.payerCpf?.replace(
					/(\d{3})(\d{3})(\d{3})(\d{2})/,
					'$1.$2.$3-$4'
				) ?? '-',
		},
		{
			title: 'Valor',
			render: (it) =>
				it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				}),
		},
		{
			title: 'Tipo',
			render(it) {
				const formattedMethod =
					PAYMENT_METHODS.find((m) => m.value === it.paymentMethod)
						?.name ?? '-'
				return formattedMethod ?? '-'
			},
		},
		{
			title: 'Pago em',
			render: (it) =>
				it.paymentDate
					? dayjs(it.paymentDate).utc().format('DD/MM/YYYY')
					: '',
		},
	]

	const exportPdfMutation = useMutation({
		mutationFn: async (props: GetPaymentsProps) => {
			const repo = new PaymentsRepository()
			const { data } = await repo.getPayments({
				...props,
				limit: Number.MAX_SAFE_INTEGER,
			})
			const blob = await Pdf.pdf(
				<ReportPdf
					title="Relatório fiscal de entrada"
					sections={data.map((it) => ({
						data: columns.map((col) => ({
							title: col.title,
							body: col.render(it),
						})),
					}))}
				/>
			).toBlob()

			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.download = 'relatorio-fiscal-entrada.pdf'
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			URL.revokeObjectURL(url)
		},
	})
	const onExportPdf = () => exportPdfMutation.mutate(queryParams)

	return (
		<Stack gap="6" h="100%">
			<ReportFilters onSearchChange={setLocalSearchParams}>
				<ReportFilters.Search
					search={localSearchParams}
					placeholder="Busque pelo nome do paciente"
				/>
				<ReportFilters.DateRange search={localSearchParams} />
				<Flex alignItems="center" gap="2">
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Dentista
					</Text>
					<Select
						size="sm"
						name="dentist"
						value={localSearchParams.get('dentist')}
						_dark={{ bg: '#182533', option: { bg: '#182533' } }}
						placeholder="Selecione"
					>
						{dentists?.map((option) => (
							<option key={option.id} value={option.id}>
								{option.name}
							</option>
						))}
					</Select>
				</Flex>
			</ReportFilters>

			{hasSearch ? (
				<>
					{!!(payments && payments.length) && (
						<Flex>
							<Button
								colorScheme="primary"
								size="sm"
								textTransform="uppercase"
								fontSize="xs"
								style={{ marginLeft: 'auto' }}
								type="button"
								onClick={onExportPdf}
								isLoading={exportPdfMutation.isPending}
							>
								Exportar PDF
							</Button>
						</Flex>
					)}

					<TableContainer
						bg="white"
						_dark={{ bg: '#182533' }}
						p="4"
						rounded="12"
						h="100%"
						mb="6"
						position="relative"
					>
						<Table variant="striped">
							<Thead>
								<tr>
									{columns.map(({ title }) => (
										<Th
											fontSize="sm"
											key={`thead:${title}`}
										>
											{title}
										</Th>
									))}
								</tr>
							</Thead>
							<Tbody>
								{payments &&
									!isEmpty &&
									payments.map((it) => {
										return (
											<tr key={it.id}>
												{columns.map((col) => (
													<Td
														key={`td:${it.id}:${col.title}`}
													>
														<Text>
															{col.render(it)}
														</Text>
													</Td>
												))}
											</tr>
										)
									})}

								{(query.isPlaceholderData ||
									query.isLoading) && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Spinner />
											</Center>
										</td>
									</tr>
								)}

								{isEmpty && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>Nada aqui ainda</Text>
											</Center>
										</td>
									</tr>
								)}
								{query.isLoadingError && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Algo deu errado ao carregar
													esses dados...
												</Text>
												<Text>
													{String(query.error)}
												</Text>
											</Center>
										</td>
									</tr>
								)}
							</Tbody>
						</Table>
					</TableContainer>

					{total && (
						<Text ml="61%" fontWeight={600}>
							Total:{' '}
							{total.toLocaleString([], {
								style: 'currency',
								currency: 'BRL',
							})}
						</Text>
					)}

					{paginationInfo && paginationInfo.total > 0 && (
						<Flex
							alignItems="center"
							justify="end"
							flexDirection={{ base: 'column', md: 'row' }}
							columnGap="5"
							rowGap="3"
						>
							<TablePagination
								total={paginationInfo.total}
								perPage={paginationInfo.perPage}
								page={page}
								onPageChange={onPageChange}
							/>

							<Text
								fontSize="xs"
								fontWeight="medium"
								textTransform="uppercase"
							>
								{paginationInfo.total} registros
							</Text>
						</Flex>
					)}
				</>
			) : (
				<Center flex={1}>
					<Text>
						Busque pelo nome do paciente ou pela data para obter
						resultados
					</Text>
				</Center>
			)}
		</Stack>
	)
}

function ReportFiscalSaida() {
	const [localSearchParams, setLocalSearchParams] = useState(
		new URLSearchParams(window.location.search)
	)
	const page = Math.abs(Number(localSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setLocalSearchParams((init) => {
			init.set('page', String(nextPage))
			return new URLSearchParams(init)
		})
	}

	const searchObject = Object.fromEntries(localSearchParams)
	const hasSearch = Boolean(
		localSearchParams.get('search') ||
			localSearchParams.get('startDate') ||
			localSearchParams.get('endDate')
	)

	const query = useBillsQuery(
		{ isTaxable: true, ...searchObject, page },
		{ enabled: hasSearch }
	)
	const bills = query.data?.data
	const paginationInfo = query.data?.meta
	const total = query.data?.total

	const isEmpty = bills?.length <= 0

	type Column = { title: string; render: (it: Bill) => string }
	const columns: Column[] = [
		{ title: 'Conta', render: (it) => it.name },
		{
			title: 'Data de Pagamento',
			render: (it) =>
				it.paymentDate
					? dayjs(it.paymentDate).utc().format('DD/MM/YYYY')
					: '-',
		},
		{
			title: 'Data de Vencimento',
			render: (it) =>
				it.dueDate ? dayjs(it.dueDate).utc().format('DD/MM/YYYY') : '-',
		},
		{
			title: 'Valor',
			render: (it) =>
				it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				}),
		},
	]

	return (
		<Stack gap="6" h="100%">
			<ReportFilters onSearchChange={setLocalSearchParams}>
				<ReportFilters.Search
					search={localSearchParams}
					placeholder="Busque pelo nome"
				/>
				<ReportFilters.DateRange search={localSearchParams} />
			</ReportFilters>

			{hasSearch ? (
				<>
					{!!(bills && bills.length) && (
						<Flex>
							<ReportPdfDownload
								document={
									<ReportPdf
										title="Relatório fiscal de saída"
										sections={bills.map((it) => ({
											data: columns.map((col) => ({
												title: col.title,
												body: col.render(it),
											})),
										}))}
									/>
								}
								fileName={`relatorio-fiscal-saida-pag-${page}.pdf`}
								style={{ marginLeft: 'auto' }}
							/>
						</Flex>
					)}

					<TableContainer
						bg="white"
						_dark={{ bg: '#182533' }}
						p="4"
						rounded="12"
						h="100%"
						mb="6"
						position="relative"
						flex={1}
					>
						<Table variant="striped">
							<Thead>
								<tr>
									{columns.map((col) => (
										<Th
											key={`rfs:${col.title}`}
											fontSize="sm"
										>
											{col.title}
										</Th>
									))}
								</tr>
							</Thead>
							<Tbody>
								{bills &&
									!isEmpty &&
									bills.map((it) => {
										return (
											<tr key={it.id}>
												{columns.map((col) => (
													<Td
														key={`rfs:${it.id}:${col.title}`}
													>
														{col.render(it)}
													</Td>
												))}
											</tr>
										)
									})}

								{!query.data && query.isLoading && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Spinner />
											</Center>
										</td>
									</tr>
								)}

								{isEmpty && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>Nada aqui ainda</Text>
											</Center>
										</td>
									</tr>
								)}
								{query.isLoadingError && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Algo deu errado ao carregar
													esses dados...
												</Text>
												<Text>
													{String(query.error)}
												</Text>
											</Center>
										</td>
									</tr>
								)}
							</Tbody>
						</Table>
					</TableContainer>

					{total && (
						<Text ml="auto" fontWeight={600}>
							Total:{' '}
							{total.toLocaleString([], {
								style: 'currency',
								currency: 'BRL',
							})}
						</Text>
					)}

					{paginationInfo && paginationInfo.total > 0 && (
						<Flex
							alignItems="center"
							justify="end"
							flexDirection={{ base: 'column', md: 'row' }}
							columnGap="5"
							rowGap="3"
						>
							<TablePagination
								total={paginationInfo.total}
								perPage={paginationInfo.perPage}
								page={page}
								onPageChange={onPageChange}
							/>

							<Text
								fontSize="xs"
								fontWeight="medium"
								textTransform="uppercase"
							>
								{paginationInfo.total} registros
							</Text>
						</Flex>
					)}
				</>
			) : (
				<Center flex={1}>
					<Text>
						Busque pelo nome do paciente ou pela data para obter
						resultados
					</Text>
				</Center>
			)}
		</Stack>
	)
}

function ReportContabilEntrada() {
	const [localSearchParams, setLocalSearchParams] = useState(
		new URLSearchParams(window.location.search)
	)
	const page = Math.abs(Number(localSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setLocalSearchParams((init) => {
			init.set('page', String(nextPage))
			return new URLSearchParams(init)
		})
	}

	const searchObject = Object.fromEntries(localSearchParams)
	const hasSearch = Boolean(
		localSearchParams.get('search') ||
			localSearchParams.get('startDate') ||
			localSearchParams.get('endDate') ||
			localSearchParams.get('dentist')
	)

	const query = usePaymentsQuery(
		{ ...searchObject, page, status: 'paid' },
		{ enabled: hasSearch }
	)
	const payments = query.data?.data
	const paginationInfo = query.data?.meta
	const isEmpty = payments?.length <= 0
	const total = query.data?.totalBy?.all

	const dentistsQuery = useQuery({
		queryKey: ['professionals'],
		queryFn: new ProfessionalsRepository().getProfessionals,
	})
	const dentists = dentistsQuery.data

	type Column = { title: string; render: (it: Payment) => string }
	const columns: Column[] = [
		{ title: 'Paciente', render: (it) => it.customer?.name },
		{
			title: 'Valor',
			render: (it) =>
				it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				}),
		},
		{
			title: 'Tipo',
			render(it) {
				const formattedMethod =
					PAYMENT_METHODS.find((m) => m.value === it.paymentMethod)
						?.name ?? '-'
				return formattedMethod ?? '-'
			},
		},
		{
			title: 'Pago em',
			render: (it) =>
				it.paymentDate
					? dayjs(it.paymentDate).utc().format('DD/MM/YYYY')
					: '',
		},
	]

	return (
		<Stack gap="6" h="100%">
			<ReportFilters onSearchChange={setLocalSearchParams}>
				<ReportFilters.Search
					search={localSearchParams}
					placeholder="Busque pelo nome do paciente"
				/>
				<ReportFilters.DateRange search={localSearchParams} />
				<Flex alignItems="center" gap="2">
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Dentista
					</Text>
					<Select
						size="sm"
						name="dentist"
						value={localSearchParams.get('dentist')}
						_dark={{ bg: '#182533', option: { bg: '#182533' } }}
						placeholder="Selecione"
					>
						{dentists?.map((option) => (
							<option key={option.id} value={option.id}>
								{option.name}
							</option>
						))}
					</Select>
				</Flex>
			</ReportFilters>

			{hasSearch ? (
				<>
					{!!(payments && payments.length) && (
						<Flex>
							<ReportPdfDownload
								document={
									<ReportPdf
										title="Relatório contábil de entrada"
										sections={payments.map((it) => ({
											data: columns.map((col) => ({
												title: col.title,
												body: col.render(it),
											})),
										}))}
									/>
								}
								fileName={`relatorio-contabil-entrada.pdf`}
								style={{ marginLeft: 'auto' }}
							/>
						</Flex>
					)}

					<TableContainer
						bg="white"
						_dark={{ bg: '#182533' }}
						p="4"
						rounded="12"
						h="100%"
						mb="6"
						position="relative"
					>
						<Table variant="striped">
							<Thead>
								<tr>
									{columns.map((col) => (
										<Th
											fontSize="sm"
											key={`rce:${col.title}`}
										>
											{col.title}
										</Th>
									))}
								</tr>
							</Thead>
							<Tbody>
								{payments &&
									!isEmpty &&
									payments.map((it) => {
										return (
											<tr key={it.id}>
												{columns.map((col) => (
													<Td
														key={`rce:${it.id}:${col.title}`}
													>
														{col.render(it)}
													</Td>
												))}
											</tr>
										)
									})}

								{(query.isPlaceholderData ||
									query.isLoading) && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Spinner />
											</Center>
										</td>
									</tr>
								)}

								{isEmpty && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>Nada aqui ainda</Text>
											</Center>
										</td>
									</tr>
								)}
								{query.isLoadingError && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Algo deu errado ao carregar
													esses dados...
												</Text>
												<Text>
													{String(query.error)}
												</Text>
											</Center>
										</td>
									</tr>
								)}
							</Tbody>
						</Table>
					</TableContainer>

					{total && (
						<Text ml="61%" fontWeight={600}>
							Total:{' '}
							{total.toLocaleString([], {
								style: 'currency',
								currency: 'BRL',
							})}
						</Text>
					)}

					{paginationInfo && paginationInfo.total > 0 && (
						<Flex
							alignItems="center"
							justify="end"
							flexDirection={{ base: 'column', md: 'row' }}
							columnGap="5"
							rowGap="3"
						>
							<TablePagination
								total={paginationInfo.total}
								perPage={paginationInfo.perPage}
								page={page}
								onPageChange={onPageChange}
							/>

							<Text
								fontSize="xs"
								fontWeight="medium"
								textTransform="uppercase"
							>
								{paginationInfo.total} registros
							</Text>
						</Flex>
					)}
				</>
			) : (
				<Center flex={1}>
					<Text>
						Busque pelo nome do paciente ou pela data para obter
						resultados
					</Text>
				</Center>
			)}
		</Stack>
	)
}

function ReportContabilSaida() {
	const [localSearchParams, setLocalSearchParams] = useState(
		new URLSearchParams(window.location.search)
	)
	const page = Math.abs(Number(localSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setLocalSearchParams((init) => {
			init.set('page', String(nextPage))
			return new URLSearchParams(init)
		})
	}

	const searchObject = Object.fromEntries(localSearchParams)

	const hasSearch = Boolean(
		localSearchParams.get('search') ||
			localSearchParams.get('startDate') ||
			localSearchParams.get('endDate') ||
			localSearchParams.get('status')
	)

	const query = useBillsQuery(
		{ ...searchObject, page },
		{ enabled: hasSearch }
	)
	const bills = query.data?.data
	const paginationInfo = query.data?.meta
	const total = query.data?.total
	const isEmpty = bills?.length <= 0

	type Column = { title: string; render: (it: Bill) => string }
	const columns: Column[] = [
		{ title: 'Conta', render: (it) => it.name },
		{
			title: 'Data de Pagamento',
			render: (it) =>
				it.paymentDate
					? dayjs(it.paymentDate).utc().format('DD/MM/YYYY')
					: '-',
		},
		{
			title: 'Data de Vencimento',
			render: (it) =>
				it.dueDate ? dayjs(it.dueDate).utc().format('DD/MM/YYYY') : '-',
		},
		{
			title: 'Valor',
			render: (it) =>
				it.value?.toLocaleString([], {
					style: 'currency',
					currency: 'BRL',
				}),
		},
	]

	return (
		<Stack gap="6" h="100%">
			<ReportFilters onSearchChange={setLocalSearchParams}>
				<ReportFilters.Search
					search={localSearchParams}
					placeholder="Busque pelo nome"
				/>
				<ReportFilters.DateRange search={localSearchParams} />
				<Flex alignItems="center" gap="2">
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Status
					</Text>
					<Select
						size="sm"
						name="status"
						value={localSearchParams.get('status')}
						_dark={{ bg: '#182533', option: { bg: '#182533' } }}
						placeholder="Selecione"
					>
						<option value="paid">Pagas</option>
						<option value="pending">Pendentes</option>
					</Select>
				</Flex>
			</ReportFilters>

			{hasSearch ? (
				<>
					{!!(bills && bills.length) && (
						<Flex>
							<ReportPdfDownload
								document={
									<ReportPdf
										title="Relatório contábil de saída"
										sections={bills.map((it) => ({
											data: columns.map((col) => ({
												title: col.title,
												body: col.render(it),
											})),
										}))}
									/>
								}
								fileName={`relatorio-contabil-saida.pdf`}
								style={{ marginLeft: 'auto' }}
							/>
						</Flex>
					)}

					<TableContainer
						bg="white"
						_dark={{ bg: '#182533' }}
						p="4"
						rounded="12"
						h="100%"
						mb="6"
						position="relative"
						flex={1}
					>
						<Table variant="striped">
							<Thead>
								<tr>
									{columns.map((col) => (
										<Th
											key={`rcs:${col.title}`}
											fontSize="sm"
										>
											{col.title}
										</Th>
									))}
								</tr>
							</Thead>
							<Tbody>
								{bills &&
									!isEmpty &&
									bills.map((it) => {
										return (
											<tr key={it.id}>
												{columns.map((col) => (
													<Td
														key={`rcs:${it.id}:${col.title}`}
													>
														{col.render(it)}
													</Td>
												))}
											</tr>
										)
									})}

								{!query.data && query.isLoading && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Spinner />
											</Center>
										</td>
									</tr>
								)}

								{isEmpty && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>Nada aqui ainda</Text>
											</Center>
										</td>
									</tr>
								)}
								{query.isLoadingError && (
									<tr style={{ display: 'block' }}>
										<td>
											<Center
												flexDirection="column"
												position="absolute"
												h="100%"
												top="50%"
												left="50%"
												transform="translate(-50%,-50%)"
											>
												<Text>
													Algo deu errado ao carregar
													esses dados...
												</Text>
												<Text>
													{String(query.error)}
												</Text>
											</Center>
										</td>
									</tr>
								)}
							</Tbody>
						</Table>
					</TableContainer>

					{total && (
						<Text ml="auto" fontWeight={600}>
							Total:{' '}
							{total.toLocaleString([], {
								style: 'currency',
								currency: 'BRL',
							})}
						</Text>
					)}

					{paginationInfo && paginationInfo.total > 0 && (
						<Flex
							alignItems="center"
							justify="end"
							flexDirection={{ base: 'column', md: 'row' }}
							columnGap="5"
							rowGap="3"
						>
							<TablePagination
								total={paginationInfo.total}
								perPage={paginationInfo.perPage}
								page={page}
								onPageChange={onPageChange}
							/>

							<Text
								fontSize="xs"
								fontWeight="medium"
								textTransform="uppercase"
							>
								{paginationInfo.total} registros
							</Text>
						</Flex>
					)}
				</>
			) : (
				<Center flex={1}>
					<Text>
						Busque pelo nome do paciente ou pela data para obter
						resultados
					</Text>
				</Center>
			)}
		</Stack>
	)
}

interface StockMovement {
	_id: string
	date?: string
	name?: string
	product?: { name: string }
	quantity?: number
}
function ReportStockMovements(): JSX.Element {
	const [localSearchParams, setLocalSearchParams] = useState(
		new URLSearchParams(window.location.search)
	)
	const page = Math.abs(Number(localSearchParams.get('page')) || 1)
	const onPageChange = (nextPage: number) => {
		setLocalSearchParams((init) => {
			init.set('page', String(nextPage))
			return new URLSearchParams(init)
		})
	}

	const search = { ...Object.fromEntries(localSearchParams), page }
	const query = useQuery({
		queryKey: ['products', 'stock_movements', search],
		queryFn: async () => {
			const _search = new URLSearchParams(
				Object.entries(search).map(([key, value]) => [
					key,
					String(value),
				])
			)
			const response = await api.get<StockMovementsResponse>(
				`/stock-movements?${_search}`
			)
			return response.data

			interface StockMovementsResponse {
				data: StockMovement[]
				meta: { perPage: number; total: number }
			}
		},
	})
	const movements = query.data?.data
	const paginationInfo = query.data?.meta

	const isEmpty = movements?.length <= 0

	type Column = {
		title: string
		render: (it: StockMovement, mode?: 'html' | 'pdf') => React.ReactNode
	}
	const columns: Column[] = [
		{ title: 'Usuário', render: (it) => it.name },
		{
			title: 'Ação',
			render: (it) => (it.quantity < 0 ? 'Saída' : 'Entrada'),
		},
		{
			title: 'Adicionado/Retirado',
			render: (it, mode) => {
				const value = Math.abs(it.quantity)
				if (mode === 'html')
					return <Text textAlign="center">{value}</Text>
				return value
			},
		},
		{ title: 'Produto', render: (it) => it.product?.name ?? '-' },
		{
			title: 'Data',
			render: (it) =>
				it.date ? dayjs(it.date).utc().format('DD/MM/YYYY') : '-',
		},
	]

	return (
		<Stack gap="6" h="100%">
			<ReportFilters
				onSearchChange={(changed) => {
					setLocalSearchParams(changed)
					console.log('changed', changed)
				}}
			>
				<div style={{ flex: 1 }} />
				<ReportFilters.DateRange search={localSearchParams} />
				<Flex alignItems="center" gap="2">
					<Text
						fontSize="sm"
						fontWeight="600"
						whiteSpace="nowrap"
						textTransform="uppercase"
					>
						Ação
					</Text>
					<Select
						size="sm"
						name="type"
						value={localSearchParams.get('type')}
						_dark={{ bg: '#182533', option: { bg: '#182533' } }}
						placeholder="Todos"
					>
						{[
							{ value: 'positive', label: 'Entrada' },
							{ value: 'negative', label: 'Saída' },
						].map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
				</Flex>
			</ReportFilters>

			{Boolean(movements && movements.length) && (
				<Flex>
					<ReportPdfDownload
						document={
							<ReportPdf
								title="Relatório de entradas/saídas de estoque"
								sections={movements.map((it) => ({
									data: columns.map((col) => ({
										title: col.title,
										body: col.render(it),
									})),
								}))}
							/>
						}
						fileName={`relatorio-movimento-de-estoque.pdf`}
						style={{ marginLeft: 'auto' }}
					/>
				</Flex>
			)}

			<TableContainer
				bg="white"
				_dark={{ bg: '#182533' }}
				p="4"
				rounded="12"
				h="100%"
				mb="6"
				position="relative"
				flex={1}
			>
				<Table variant="striped">
					<Thead>
						<tr>
							{columns.map((col) => (
								<Th
									key={`movements:${col.title}`}
									fontSize="sm"
								>
									{col.title}
								</Th>
							))}
						</tr>
					</Thead>
					<Tbody>
						{movements &&
							!isEmpty &&
							movements.map((it) => {
								return (
									<tr key={it._id}>
										{columns.map((col) => (
											<Td
												key={`movements:${it._id}:${col.title}`}
											>
												{col.render(it, 'html')}
											</Td>
										))}
									</tr>
								)
							})}

						{!query.data && query.isLoading && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Spinner />
									</Center>
								</td>
							</tr>
						)}

						{isEmpty && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>Nada aqui ainda</Text>
									</Center>
								</td>
							</tr>
						)}
						{query.isLoadingError && (
							<tr style={{ display: 'block' }}>
								<td>
									<Center
										flexDirection="column"
										position="absolute"
										h="100%"
										top="50%"
										left="50%"
										transform="translate(-50%,-50%)"
									>
										<Text>
											Algo deu errado ao carregar esses
											dados...
										</Text>
										<Text>{String(query.error)}</Text>
									</Center>
								</td>
							</tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
			{paginationInfo && paginationInfo.total > 0 && (
				<Flex
					alignItems="center"
					justify="end"
					flexDirection={{ base: 'column', md: 'row' }}
					columnGap="5"
					rowGap="3"
				>
					<TablePagination
						total={paginationInfo.total}
						perPage={paginationInfo.perPage}
						page={page}
						onPageChange={onPageChange}
					/>

					<Text
						fontSize="xs"
						fontWeight="medium"
						textTransform="uppercase"
					>
						{paginationInfo.total} registros
					</Text>
				</Flex>
			)}
		</Stack>
	)
}

function ReportFilters({
	children,
	onSearchChange,
}: {
	children: React.ReactNode
	onSearchChange: (s: URLSearchParams) => void
}) {
	return (
		<Flex
			gap="6"
			flexDirection={{ base: 'column', md: 'row' }}
			as="form"
			onChange={(e) => {
				const form: unknown = e.currentTarget
				const formData = new FormData(form as HTMLFormElement)
				onSearchChange(
					new URLSearchParams(
						Array.from(formData, ([k, v]) => [k, String(v)])
					)
				)
			}}
			onSubmit={(e) => e.preventDefault()}
		>
			{children}
		</Flex>
	)
}
ReportFilters.Search = function RFS({
	search,
	...props
}: InputProps & { search: URLSearchParams }) {
	return (
		<InputGroup flex={1} size="sm" maxW="543px" mr="auto">
			<InputLeftElement>
				<Search2Icon />
			</InputLeftElement>
			<Input
				name="search"
				defaultValue={search.get('search')}
				bg="white"
				_dark={{ bg: '#182533' }}
				{...props}
			/>
		</InputGroup>
	)
}
ReportFilters.DateRange = function RFDR({
	search,
}: {
	search: URLSearchParams
}) {
	return (
		<>
			<InputGroup
				size="sm"
				display="flex"
				alignItems="center"
				w="auto"
				gap="3"
			>
				<Text
					fontSize="sm"
					fontWeight="600"
					whiteSpace="nowrap"
					textTransform="uppercase"
				>
					Data Início
				</Text>
				<Input
					size="sm"
					name="startDate"
					defaultValue={search.get('startDate')}
					bg="white"
					_dark={{ bg: '#182533' }}
					type="date"
				/>
			</InputGroup>

			<InputGroup
				size="sm"
				display="flex"
				alignItems="center"
				w="auto"
				gap="3"
			>
				<Text
					fontSize="sm"
					fontWeight="600"
					whiteSpace="nowrap"
					textTransform="uppercase"
				>
					Data Fim
				</Text>
				<Input
					size="sm"
					name="endDate"
					defaultValue={search.get('endDate')}
					bg="white"
					_dark={{ bg: '#182533' }}
					type="date"
				/>
			</InputGroup>
		</>
	)
}
